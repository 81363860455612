import React, { useState } from "react";
import cn from "classnames";
import {FilterModel} from "../../../../../models/filter";
import {inject, observer} from "mobx-react";
import reviewService from "../../../../../services/review";
import applicationService from "../../../../../services/application";
import okAnalyticsService from "../../../../../services/okAnalytics";
import integrationService from "../../../../../services/integration";
import {Button} from "primereact/button";
import {Sidebar} from "primereact/sidebar";
import "./Claims.scss";
import qrTransitionStore from "../../../../../stores/QrTransitionStore";
import {Link} from "react-router-dom";

const Download = ({ filter, isMobile, uiStore, userStore, dest, reviewFilterStore, qrTransitionStore }) => {
    const [visibleRight, setVisibleRight] = useState(false);
    const filterFromStore = filter;

    const OkMessage = () => {
        uiStore.setMessage(
            {
                severity: 'success',
                summary: '',
                detail: 'Отчет будет отправлен на почту '+ userStore.profile.user.email,
                sticky: false,
            }
        );
    }

    const errorMessage = () => {
        uiStore.setMessage(
            {
                severity: 'error',
                summary: '',
                detail: 'Пожалуйста, дождитесь поступления Вам предыдущей выгрузки данных',
                sticky: true,
            }
        );

    }

    const logging = (target) => {
        okAnalyticsService.addEntity({
            page: target,
            target: 'download_form',
            event: 'download_xls',
            info: {
                user_id: userStore.profile.user.id
            }
        });

    }

    return (
        <>
        <Button
            label='Excel'
            className={cn('p-button-outlined', 'excel', { 'mobile': isMobile })}
            onClick={()=>{
                setVisibleRight(true)
            }}
        />

        <Sidebar
            visible={visibleRight}
            position="right"
            onHide={() => setVisibleRight(false)}
            className={cn('claims', 'download-xls', { 'mobile': isMobile })}
            blockScroll={true}
            icons={() => (
                <button
                    className={cn('p-link', 'close-text')}
                    onClick={() => setVisibleRight(false)}
                >
                    Закрыть
                </button>
            )}
        >
            <div className={cn('claims__title')}>
                <h3>Получить в формате Excel</h3>
            </div>

            <div className={cn('claims__body', 'main-form')}>
                <p>
                    Выгрузка данных в Excel будет сформирована и направлена вам в течение нескольких минут на почту: {userStore.profile.user.email}
                </p>
                <p>
                    При выгрузке будут применены выбранные фильтры.
                </p>
                <p>
                    Посмотреть список всех созданных вами выгрузок, отчетов и скачать их возможно в разделе <nobr><Link to={'/settings/data-uploads-and-reports'}>Выгрузки и отчеты</Link>.</nobr>
                </p>

                <div className={cn('btn-form')}>
                    <Button
                        label="Получить на почту"
                        className={cn('p-button-outlined')}
                        onClick={() => {

                            switch (dest) {
                                case 'appl':
                                    logging('application_list')

                                    applicationService.exportXls(new FilterModel(filterFromStore).getToAPI()).then(result => {
                                        if(result.task) {
                                            setVisibleRight(false)
                                            OkMessage()
                                        }
                                    })
                                        .catch(_ => {
                                            setVisibleRight(false)
                                            errorMessage()
                                        })
                                    break;
                                case 'integration':
                                    logging('integration_list');

                                    integrationService.exportXls(filterFromStore).then(result => {
                                        if(result) {
                                            setVisibleRight(false)
                                            OkMessage()
                                        }
                                    })
                                        .catch(_ => {
                                            setVisibleRight(false)
                                            errorMessage()
                                        })

                                    break;
                                case 'qr':
                                    logging('qr_list');

                                    qrTransitionStore.exportXls(qrTransitionStore.parseFilterToApi()).then(result => {
                                        if(result.task) {
                                            setVisibleRight(false)
                                            OkMessage()
                                        }
                                    })
                                        .catch(_ => {
                                            setVisibleRight(false)
                                            errorMessage()
                                        })
                                    break;
                                case 'review':
                                    logging('monitoring_list');

                                    reviewService.exportXls(reviewFilterStore.parseFilterToApi()).then(result => {
                                        if(result.task) {
                                            setVisibleRight(false)
                                            OkMessage()
                                        }
                                    }).catch(_ => {
                                        setVisibleRight(false)
                                        errorMessage()
                                    })
                                    break;
                                default:
                                    setVisibleRight(false)
                            }
                        }}
                        icon="pi pi-check"
                    />
                    <Button
                        label="Отмена"
                        icon="pi pi-times"
                        className={cn('p-button-outlined')}
                        onClick={() => setVisibleRight(false)}
                    />
                </div>
            </div>
        </Sidebar>
        </>
    );
}

export default inject('reviewFilterStore', 'qrTransitionStore')(observer(Download));