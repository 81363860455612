import React, { useEffect, useState } from "react";
import cn from "classnames";
import { inject, observer } from "mobx-react";
import okAnalyticsService from "../../../../services/okAnalytics";
import { Rating } from "primereact/rating";
import { Button } from "primereact/button";
import { TextToolTip } from '../../../common/ToolTip';
import BranchesBlock from "./common/BranchesBlock";
import AnswerList from "./AnswerList";
import AnswerAdd from "./common/AnswerAdd";
import SourceBlock from "./common/SourceBlock";
import AnswerButton from "./common/AnswerButton";
import ClaimsSlideBar from "./common/ClaimsSlideBar";
import ClaimStatus from "./common/ClaimStatus";
import { Link } from "react-router-dom";
import FooterTag from "./tag/FooterTag";
import AnswerCount from "./common/AnswerCount";
import ClaimButton from "./common/ClaimButton";
import ReviewsImages from "./common/ReviewsImages";

/**
 * @param {ExternalReview} data
 * @param {ReviewsStore} reviewsStore
 * @param {TagStore} tagStore
 * @param {boolean} single метка одиночного блока мониторинга
 * @return {JSX.Element}
 * @constructor
 */
const ReviewItem = ({ data, reviewsStore, tagStore, uiStore, single = false }) => {

    const [showQuestionsListBlock, setShowQuestionsListBlock] = useState(false);
    const [showAnswerBlock, setShowAnswerBlock] = useState(false);
    const [answerList, setAnswerList] = useState([]);
    const [review, setReview] = useState(data);

    const [visibleRight, setVisibleRight] = useState(false);
    const [branches, setBranches] = useState([]);

    const [toggle, setToggle] = useState(true);

    const [answer, setAnswer] = useState({});
    const [claimsAllowed, setClaimsAllowed] = useState(false);
    const [claimStatus, setClaimStatus] = useState('');

    useEffect(() => {

        if (single) {
            tagStore.getTagList();
        }
        setReview(data);
        setClaimsAllowed(data.claim.allowed);
        setClaimStatus(data.claim.status);
    }, []);

    useEffect(() => {
        !!review?.text?.length && setAnswer(getText());
    }, [review]);

    useEffect(() => {
        setBranches(data.branches);
    }, [data])

    useEffect(() => {
        if (showQuestionsListBlock === true) {
            reviewsStore.loadAnswers(data.id)
                .then((result) => {
                    setAnswerList(result);
                });
        }
    }, [showQuestionsListBlock]);

    const getText = () => {
        const textArr = review.text.replaceAll(/\n\n+/g, "\n").split('\n');

        const text = textArr.map((item, key) => {
            return <span key={key}>{item}<br/></span>
        });
        const showShadow = !!(textArr.length > 4 || review.text.length > 350);

        return {
            text,
            showShadow,
            log: `строк ${textArr.length}. символов ${review.text.length}.`
        }
    }

    const addAnswer = (data) => {
        reviewsStore.loadReview(data.reviewId)
            .then((result) => {
                setReview(result)
            });

        const newComment = reviewsStore.addNewCommentInList(data.id, data.reviewId, data.text);
        setAnswerList(answerList => [...answerList, newComment]);
        setShowQuestionsListBlock(true);
        setShowAnswerBlock(false);

        okAnalyticsService.addEntity({
            page: 'review_list',
            target: 'answer_form',
            event: 'send_answer',
        });

    }

    const reloadAnswer = (deleteStatus = false, idAnswer = null) => {

        if (deleteStatus) {
            let copy = [...answerList]
            const index = copy.findIndex(answer => answer.id === idAnswer)
            copy.splice(index, 1)
            setAnswerList(copy);
            if (copy.length === 0) {
                setShowQuestionsListBlock(false);
            }
        }

        reviewsStore.loadReview(review.id)
            .then((result) => {
                setReview(result)
                return reviewsStore.loadAnswers(review.id)
            })
            .then((result) => {
                setAnswerList(result);
            })
            .catch((error) => {
                console.log(error)
            });
    }

    const checkPlatform = () => {
        return review.adplatform.type === '2gis' && review.subsourceCode === '2gis'
    }


    return (
        <>{review && <div className={cn('review-list__item', 'item')}>
            <div className={cn('item__wrapper')}>
                <div className={cn('item__left')}>
                    <SourceBlock
                        item={review}
                    />
                    <Rating
                        className={cn('rating', 'no-cursor')}
                        value={review.score}
                        cancel={false}
                    />

                    {review.sentiment && <Sentiment
                        value={reviewsStore.getSentiments(review.sentiment)}
                    />}

                </div>
                <div className={cn('item__right')}>
                    <div className={cn('item__body-header')}>
                        <div className={cn('item__author', 'author')}>
                            <span className={cn('author__name')}>{!review.author.anonymous && review.author.name}</span>
                            <br/>
                            <span
                                className={cn('author__date')}
                            >
                                <TextToolTip content={`Дата сбора отзыва: ${review.createdAt}`}>
                                    {review.postedAt}
                                </TextToolTip>
                                {review.matchingApp &&
                                    <a href={`/application/detail/${review.matchingApp}`} target="_blank">
                                        <i className="pi pi-check"/>
                                    </a>
                                }
                            </span>
                        </div>
                        <div className={cn('item__status')}>
                            <BranchesBlock
                                branches={branches}
                                isShort={review.isShortBranchesBlock}
                            />
                            {/* TODO Убрать в отдельный компонент */}
                            {review.deletedAt && <div className={cn('status', 'type5')}>Снят с публикации</div>}
                            {review.published && !review.deletedAt &&
                                <div className={cn('status', 'type1')}>Опубликован</div>}
                            {!review.published && !review.deletedAt &&
                                <div className={cn('status', 'type2')}>Не опубликован</div>}
                            {review.type === 'application' && <div className={cn('status', 'type3')}>OKR заявка</div>}
                            {!review.answer.official && <div className={cn('status', 'type4')}>Без оф. ответа</div>}

                        </div>
                    </div>
                    <div className={cn('item__body', { 'hide': !single && (toggle && answer.showShadow) })}>
                        {answer.text}
                        {!single && <div
                            className={cn('shadow')}
                            onClick={() => {
                                setToggle(false);
                            }}
                        />}
                    </div>
                    {!single && <div className={cn('show-more-btn')}>
                        {(toggle && answer.showShadow) && <>
                            <a
                                href="#"
                                onClick={(e) => {
                                    setToggle(false);
                                    return e.preventDefault();
                                }}
                            >Показать больше</a>
                        </>}
                    </div>}

                    {!!review.attaches.length && <div className={cn('item__images')}>
                        <ReviewsImages images={review.attaches}/>
                    </div>}

                    <div className={cn('replay-toggle-btn')}>
                        <div className={cn('replay-toggle-btn__wrapper')}>
                            <AnswerCount
                                review={review}
                                showQuestionsListBlock={showQuestionsListBlock}
                                setShowQuestionsListBlock={setShowQuestionsListBlock}
                            />
                            <span className={cn('no-answer')}>{!review.answer.count && 'Нет ответов'}</span>
                        </div>
                    </div>

                    {showQuestionsListBlock && <>
                        <AnswerList
                            items={answerList}
                            reviewCreatedAtDate={review.createdAtRaw}
                            reviewPostedAtDate={review.postedAtRaw}
                            reloadAnswer={reloadAnswer}
                        />
                    </>}

                    {showAnswerBlock && <>
                        <AnswerAdd
                            reviewId={review.id}
                            review={review}
                            addAnswer={addAnswer}
                            setShowAnswerBlock={setShowAnswerBlock}
                        />
                    </>}
                </div>
            </div>
            <div className={cn('item__footer')}>
                <span className={cn('review-id')}>
                    <Link to={`/monitoring/detail/${review.id}`} target="_blank"
                          rel="noopener noreferrer">{review.id}</Link>
                </span>
                <div className={cn('item__footer-left')}>
                    <AnswerButton
                        item={review}
                        setShowAnswerBlock={setShowAnswerBlock}
                        showAnswerBlock={showAnswerBlock}
                    />
                </div>

                <div className={cn('item__footer-right')}>
                    <div className={cn('tag__wrapper')}>
                        <FooterTag
                            defaultValue={review.tags}
                            reviewId={review.id}
                            review={review}
                        />
                    </div>
                    <div className={cn('claim__wrapper')}>
                        {claimStatus === null ? <>
                                <ClaimButton
                                    item={review}
                                    setVisibleRight={setVisibleRight}
                                />
                                <ClaimsSlideBar
                                    reviewId={review.id}
                                    setClaimsAllowed={setClaimsAllowed}
                                    setClaimStatus={setClaimStatus}
                                    visibleRight={visibleRight}
                                    setVisibleRight={setVisibleRight}
                                    charLimit={checkPlatform()}
                                />
                            </>
                            : <ClaimStatus status={claimStatus}/>}
                    </div>
                </div>


            </div>
        </div>}</>
    );
}

const Sentiment = ({ value }) => {
    const [data, setData] = useState('')

    useEffect(() => {
        setData(value)
    }, [])

    return (<>
        {data && <span className={cn('sentiment', `sentiment__${data.code}`)}>{data.value}</span>}
    </>)
}


export default inject('reviewsStore', 'tagStore', 'uiStore')(observer(ReviewItem));
