import React, { useState} from "react";
import cn from "classnames";
import {inject, observer} from "mobx-react";
import {Button} from "primereact/button";
import {Sidebar} from "primereact/sidebar";
import "./ExportReport.scss";
import {Link} from "react-router-dom";


const ExportReport = ({ isMobile, userStore, handlerGetReport }) => {
    const [visibleRight, setVisibleRight] = useState(false);

    return (
        <>
            <Button
                label='Excel'
                className={cn('p-button-outlined', 'excel', { 'mobile': isMobile })}
                onClick={()=>{
                    setVisibleRight(true)
                }}
            />

            <Sidebar
                visible={visibleRight}
                position="right"
                onHide={() => setVisibleRight(false)}
                className={cn('claims', 'download-xls', { 'mobile': isMobile })}
                blockScroll={true}
                icons={() => (
                    <button
                        className={cn('p-link', 'close-text')}
                        onClick={() => setVisibleRight(false)}
                    >
                        Закрыть
                    </button>
                )}
            >
                <div className={cn('claims__title')}>
                    <h3>Получить в формате Excel</h3>
                </div>

                <div className={cn('claims__body', 'main-form')}>
                    <p>
                        Выгрузка данных в Excel будет сформирована и направлена вам в течение нескольких минут на почту: {userStore.profile.user.email}
                    </p>
                    <p>
                        При выгрузке будут применены выбранные фильтры.
                    </p>
                    <p>
                        Посмотреть список всех созданных вами выгрузок, отчетов и скачать их возможно в разделе <nobr><Link to={'/settings/data-uploads-and-reports'}>Выгрузки и отчеты</Link>.</nobr>
                    </p>
                    <div className={cn('btn-form')}>
                        <Button
                            label="Получить на почту"
                            className={cn('p-button-outlined')}
                            onClick={()=>{
                                handlerGetReport('GET_REPORT')
                                setVisibleRight(false)
                            }}
                            icon="pi pi-check"
                        />
                        <Button
                            label="Отмена"
                            icon="pi pi-times"
                            className={cn('p-button-outlined')}
                            onClick={() => setVisibleRight(false)}
                        />
                    </div>
                </div>
            </Sidebar>
        </>
    );
}

export default inject('reviewFilterStore')(observer(ExportReport));