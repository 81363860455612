import React, { useState } from "react";
import cn from "classnames";

import { inject, observer } from "mobx-react";
import { Button } from "primereact/button";
import { Sidebar } from "primereact/sidebar";

import { registerLocale } from "react-datepicker";
import ru from 'date-fns/locale/ru';

registerLocale('ru', ru)

import DatePicker from "react-datepicker";

/* TODO перенести в папку общих стилей*/
import "../../../components/pages/review/list/common/Claims.scss";
import moment from "moment/moment";
import dashboardService from "../../../services/dashboard";
import okAnalyticsService from "../../../services/okAnalytics";
import {Link} from "react-router-dom";


const DownloadPdf = ({ isMobile, uiStore, userStore }) => {
    const [visibleRight, setVisibleRight] = useState(false);
    const [period, setPeriod] = useState(moment().subtract(1, 'month').toDate());

    return (
        <>
            <Button
                label='PDF'
                className={cn('p-button-outlined', 'pdf', { 'mobile': isMobile })}
                icon="pi pi-download"
                onClick={() => {
                    setVisibleRight(true)
                }}
            />

            <Sidebar
                visible={visibleRight}
                position="right"
                onHide={() => setVisibleRight(false)}
                className={cn('claims', 'download-pdf', { 'mobile': isMobile })}
                blockScroll={true}
                icons={() => (
                    <button
                        className={cn('p-link', 'close-text')}
                        onClick={() => setVisibleRight(false)}
                    >
                        Закрыть
                    </button>
                )}
            >
                <div className={cn('claims__title')}>
                    <h3>Получить отчет в формате PDF</h3>
                </div>

                <div className={cn('claims__body', 'main-form')}>
                    <p>
                        PDF отчет за выбранный период будет сформирован и направлен Вам в течение нескольких минут на
                        почту: {userStore.profile.user.email}
                    </p>
                    <p>
                        Посмотреть список всех созданных вами выгрузок, отчетов и скачать их возможно в разделе <nobr><Link to={'/settings/data-uploads-and-reports'}>Выгрузки и отчеты</Link>.</nobr>
                    </p>
                    <div>
                        <DatePicker
                            selected={period}
                            onChange={(date) => setPeriod(date)}
                            dateFormat="MM.yyyy"
                            showMonthYearPicker
                            maxDate={moment().subtract(1, 'month').toDate()}
                            calendarClassName="OKReviewCalendar"
                            inline
                            locale="ru"
                        />
                    </div>
                    <div className={cn('btn-form')}>
                        <Button
                            label="Получить на почту"
                            className={cn('p-button-outlined')}
                            onClick={() => {

                                okAnalyticsService.addEntity({
                                    page: 'dashboard',
                                    target: 'download_form',
                                    event: 'download_pdf',
                                    info: {
                                        user_id: userStore.profile.user.id
                                    }
                                });


                                const filter = {
                                    year: moment(period).get('year'),
                                    month: moment(period).get('month') + 1
                                }

                                dashboardService.exportPdf(filter).then(result => {
                                    if (result.task) {
                                        setVisibleRight(false)

                                        uiStore.setMessage(
                                            {
                                                severity: 'success',
                                                summary: '',
                                                detail: 'Отчет будет отправлен на почту ' + userStore.profile.user.email,
                                                sticky: false,
                                            }
                                        );
                                    }
                                })
                                    .catch(e => {
                                        setVisibleRight(false)
                                        e.error.data.map((_) => {
                                            uiStore.setMessage(
                                                {
                                                    severity: 'error',
                                                    summary: e.error.description,
                                                    detail: 'Пожалуйста, дождитесь поступления Вам предыдущего отчета',
                                                }
                                            )
                                        });
                                    })
                            }}
                            icon="pi pi-check"
                        />
                        <Button
                            label="Отмена"
                            icon="pi pi-times"
                            className={cn('p-button-outlined')}
                            onClick={() => setVisibleRight(false)}
                        />
                    </div>
                </div>
            </Sidebar>
        </>
    );
}

export default inject('uiStore', 'userStore')(observer(DownloadPdf));