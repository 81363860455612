import React, { useEffect, useState } from "react";
import cn from "classnames";
import moment from "moment";
import { Button } from "primereact/button";
import { ToolTip } from "../../common/ToolTip";
import { inject, observer } from "mobx-react";

const Status = ({ state, status }) => {

    const STATUS = {
        not_completed: {
            text: 'формируется',
            color: {
                text: 'rgb(255, 164, 18)',
                background: 'rgb(255, 254, 220)'
            }
        },
        success: {
            text: 'Готов',
            color: {
                text: 'rgb(37, 96, 41)',
                background: 'rgb(212, 241, 213)'
            }
        },
        failure: {
            text: 'Ошибка',
            color: {
                text: 'rgb(62, 79, 114)',
                background: 'rgb(224, 226, 232)'
            }
        }
    }

    const [tag, setTag] = useState({
        color: 'rgb(62, 79, 114)',
        background: 'rgb(224, 226, 232)',
        text: 'Текст статуса'
    });

    useEffect(() => {
        if (state.code !== 'completed') {
            setTag({
                color: STATUS.not_completed.color.text,
                background: STATUS.not_completed.color.background,
                text: STATUS.not_completed.text,
            })
        }
        if (state.code === 'completed' && status.code === 'success') {
            setTag({
                color: STATUS.success.color.text,
                background: STATUS.success.color.background,
                text: STATUS.success.text,
            })
        }
        if (state.code === 'completed' && status.code === 'failure') {
            setTag({
                color: STATUS.failure.color.text,
                background: STATUS.failure.color.background,
                text: STATUS.failure.text,
            })
        }
    }, [])

    return <>
        <span className="p-tag application-tag"
              style={{ color: tag.color, backgroundColor: tag.background }}>{tag.text}</span>

    </>
}

const StorageDownloadFiles = ({ storageFilesStore, uiStore }) => {

    const [fileList, setFileList] = useState([])

    useEffect(() => {
        storageFilesStore.getFileList()
            .then(result => {
                setFileList(result.items)
            })
    }, [])

    const downloadFile = (link) => {

        if(link === '') return false;

        let a = document.createElement("a");
        document.body.appendChild(a);
        a.href = link;
        a.click();
        document.body.removeChild(a);
    }

    const [sort, setSort] = useState({
        column: "date",
        order: "desc"
    })

    useEffect(() => {

        let copy;
        switch (sort.column) {
            case "date":
                copy = [...fileList].sort((a, b) => {
                    if(sort.order === 'asc') {
                        return new Date(a.createdAt) - new Date(b.createdAt);
                    }
                    if(sort.order === 'desc') {
                        return new Date(b.createdAt) - new Date(a.createdAt);
                    }
                })
                setFileList(copy);
                break;
/*
            case "file":
                copy = [...fileList].sort((a, b) => {
                    if(sort.order === 'desc') {
                        return a.file.name < b.file.name ? -1 : 1
                    }
                    if(sort.order === 'asc') {
                        return a.file.name > b.file.name ? -1 : 1
                    }
                })
                setFileList(copy);
                break;
            case "lastname":
                copy = [...fileList].sort((a, b) => {
                    if(sort.order === 'desc') {
                        return a.createdBy.lastname < b.createdBy.lastname ? -1 : 1
                    }
                    if(sort.order === 'asc') {
                        return a.createdBy.lastname > b.createdBy.lastname ? -1 : 1
                    }
                })
                setFileList(copy);

                break;
*/
        }

    }, [sort])

    const drawSortIcon = (column) => {
        if(column === sort.column) {
            return sort.order
        }
        else return  'pi-sort-alt'
    }

    return (
        <div className={cn('page__application-list', 'page')}>
            <div className={'application-list'}>
                <div className={'title'}>
                    <h1>Выгрузки и отчеты</h1>
                </div>

                <div className={cn('application-list__table')} style={{ paddingTop: '14px' }}>
                    <div className={cn('application-list__wrapper')}>
                        <div className="p-datatable p-component">
                            <div className="p-datatable-wrapper">
                                <table role="grid">
                                    <thead className="p-datatable-thead">
                                    <tr>
                                        <th className={cn('p-sortable-column', 'date-col')}>
                                            <span className="p-column-title">Дата</span>
                                            <span className={cn("p-sortable-column-icon", "pi", "pi-fw", drawSortIcon('date'))} onClick={()=>{
                                                setSort({
                                                    column: 'date',
                                                    order: sort.order === 'desc' ? 'asc' : 'desc'
                                                })
                                            }}/>
                                        </th>
                                        <th className={cn('p-sortable-column')} style={{ width: "200px" }}>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <span className="p-column-title" style={{ marginRight: '5px' }}>Статус</span>
                                                <ToolTip mobile={false}>
                                                    Срок хранения файлов 30 дней от Даты создания
                                                </ToolTip>
                                            </div>
                                        </th>
                                        <th className={cn('p-sortable-column')}>
                                            <span className="p-column-title">Файл</span>
                                        </th>
                                        <th className={cn('p-sortable-column')} style={{width: '150px'}}>&nbsp;</th>
                                    </tr>
                                    </thead>
                                    <tbody className={cn('p-datatable-tbody')}>
                                    {fileList.length > 0 && fileList.map((item) => {
                                        return <Row item={item} key={item.id} downloadFunc={downloadFile}/>
                                    })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const Row = ({ item, downloadFunc }) => {

    return <>
        {item && <tr className="p-datatable-row">
            <td>
                <div>{moment(item.createdAt).format('DD.MM.YYYY')}</div>
                <div>{moment(item.createdAt).format('HH:mm')}</div>
            </td>
            <td>
                <Status status={item.status} state={item.state}/>
            </td>
            <td>
                <div style={{ display: 'flex' }}>
                    {item.state.code === 'completed' && item.status.code === 'success' && <>
                        {item.file?.type !== 'pdf' &&
                        <span className={cn('pi pi-file-excel')} style={{ fontSize:'24px', color:'#008E9A' }} />
                        }
                        {item.file?.type === 'pdf' &&
                            <span className={cn('pi pi-file-pdf')} style={{ fontSize:'24px', color:'#b1141d' }} />
                        }
                    </>}
                        <span style={{ marginLeft: '10px', whiteSpace: 'normal', lineHeight:'25px' }}>{item.file ? `${item.file?.name}.${item.file?.type}` : `${item.name}`}</span>

                </div>
            </td>
            <td>
                {item.state.code === 'completed' && item.status.code === 'success' && <Button
                    label="Скачать"
                    className={cn('p-button-outlined')}
                    onClick={()=>{
                        downloadFunc(item.file.link)
                    }}
                />}
            </td>
        </tr>}
    </>
}


export default inject('storageFilesStore', 'uiStore')(observer(StorageDownloadFiles));