import { action, observable, toJS } from "mobx";
import _ from "underscore";
import geoDataService from "../../services/geo/geo-category";
import { getDetailDataByPlatform } from "../../helpers";

//TODO отрефачить работу с form.setupPlace.code[legalInfoSetup] && mainCategoryList !!!!

class GeoStore {

    @observable mainCategoryList = [];

    @observable form = {
        // первый блок
        title: { //Название-Название компании
            value: "",
            required: true,
            isValid: null,
            title: "Название компании",
            code: "title",
        },
        short_title: { //Название-Отправляемое название
            value: "",
            required: false,
            isValid: null,
            title: "Короткое название компании",
            code: "short_title",
        },
        ok_link: {
            value: "",
            required: false,
            isValid: null,
            title: "",
            code: "ok_link",
        },
        tg_link: {
            value: "",
            required: false,
            isValid: null,
            title: "",
            code: "tg_link",
        },
        twitter_link: {
            value: "",
            required: false,
            isValid: null,
            title: "",
            code: "twitter_link",
        },
        viber_phone: {
            value: "",
            required: false,
            isValid: null,
            title: "",
            code: "viber_phone",
        },
        vk_url: {
            value: "",
            required: false,
            isValid: null,
            title: "",
            code: "vk_url",
        },
        whatsapp_phone: {
            value: "",
            required: false,
            isValid: null,
            title: "",
            code: "whatsapp_phone",
        },
        youtube_link: {
            value: "",
            required: false,
            isValid: null,
            title: "",
            code: "youtube_link",
        },
        setupPlace: [
            {
                code: "firstSetup",
                blocks: [],
            },
            {
                code: "legalInfoSetup",
                blocks: [
                    {
                        "source": "2gis",
                        "name": "2ГИС",
                        "active": false,
                        "fields": [
                            {
                                "fieldName": "categoryList",
                                "code": "category@2gis",
                                "fieldLabel": "Добавление категорий (начните вводить)",
                                "fieldValue": [],
                                "fieldType": "dropdown",
                                "required": true
                            }
                        ]
                    },
                    {
                        "source": "google",
                        "name": "Google",
                        "active": false,
                        "fields": [
                            {
                                "fieldName": "categoryList",
                                "code": "category@google",
                                "fieldLabel": "Добавление категорий (начните вводить)",
                                "fieldValue": [],
                                "fieldType": "dropdown",
                                "required": true
                            }
                        ]
                    },
                    {
                        "source": "yandex",
                        "name": "Яндекс.Карты",
                        "active": false,
                        "fields": [
                            {
                                "fieldName": "categoryList",
                                "code": "category@yandex",
                                "fieldLabel": "Категория",
                                "fieldValue": [],
                                "fieldType": "dropdown",
                                "required": true
                            }
                        ]
                    },
                ],
                required: true,
                isValid: null,
            },
            {
                code: "contactInfoSetup",
                blocks: [],
            },
            {
                code: "phoneInfoSetup",
                blocks: [],
            },

            {
                code: "socialMediaSetup",
                blocks: [],
            },
            {
                code: "descriptionSetup",
                blocks: [],
            },
        ],
        //Бренд и код филиала-Бренд
        fieldName3: { //кода филиала
            value: "",
            required: false,
            isValid: null,
            title: "",
            code: "",
        },
        fieldName4: { //бренд
            value: "",
            required: false,
            isValid: null,
            title: "",
            code: "",
        },
        //Адрес
        country: { //страна
            value: "",
            required: false,
            isValid: null,
            title: "",
            code: "",
        },
        region: { //область
            value: "",
            required: false,
            isValid: null,
            title: "",
            code: "",
        },
        city: { //город
            value: "",
            required: false,
            isValid: null,
            title: "",
            code: "",
        },
        postal_code: { //индекс
            value: "",
            required: false,
            isValid: null,
            title: "",
            code: "",
        },
        street: { //улица
            value: "",
            required: false,
            isValid: null,
            title: "",
            code: "",
        },
        house: { //номер дома
            value: "",
            required: false,
            isValid: null,
            title: "",
            code: "",
        },
        address: {
            value: {},
            title: "Адрес",
            code: "address"
        },
        addressComment: { //комментарий
            value: "",
            required: false,
            isValid: null,
            title: "",
            code: "",
        },
        latitude: { //широта
            value: "55.75",
            required: false,
            isValid: null,
            title: "",
            code: "",
        },
        longitude: { //долгота
            value: "37.57",
            required: false,
            isValid: null,
            title: "",
            code: "",
        },
        fieldName12: {
            value: "",
            required: false,
            isValid: null,
            title: "",
            code: "",
        },
        fieldName13: {
            value: "",
            required: false,
            isValid: null,
            title: "",
            code: "",
        },
        category: { //категории
            value: [],
            required: false,
            isValid: null,
            title: "",
            code: "category",
        },
        phones: { //Номер телефона
            value: [
                {number: '', additional: '', comment: ''},
            ],
            maxCountValue: 3,
            required: true,
            isValid: null,
            title: "",
            code: "phones",
        },

        emails: { //Email адрес
            value: [''],
            required: false,
            isValid: null,
            title: "",
            code: "emails",
        },
        websites: { //веб сайт
            value: [],
            required: false,
            isValid: null,
            title: "",
            code: "websites",
        },
        fieldName18: { //соц сети
            value: [],
            required: false,
            isValid: null,
            title: "",
            code: "",
        },
        workTime: {
            value: [],
            required: false,
            isValid: null,
            title: "",
            code: "work-time",
        },
        paymentTypes: {
            value: [],
            required: false,
            isValid: null,
            title: "",
            code: "",
        },
        fieldName19: { //Краткое описание компании
            value: [],
            required: false,
            isValid: null,
            title: "",
            code: "",
        },
        fieldName20: { //Полное описание компании
            value: [],
            required: false,
            isValid: null,
            title: "",
            code: "",
        },
    }

    dataModel = [];

    blocks = {
        firstSetup: {
            value: ['title', 'short_title'],
            del: null,
        },
        phoneInfoSetup: {
            value: ['phones'],
            del: [],
        },
        contactInfoSetup: {
            value: ['emails'],
            del: [],
        },
        socialMediaSetup:
            {
            value: ['websites'],
            del: [],
        },
        /*
                legalInfoSetup: {
                    value: ['category'],
                }
        */
    }

    deletedBlocks = []
    deletedFields = []

    modelPayment = [
        { code: 'cash', title: 'Наличные деньги', active: false },
        { code: 'card', title: 'Карта', active: false },
        { code: 'cashTransfers', title: 'Безнал. переводы', active: false },
        { code: 'electronicMoney', title: 'Электронные деньги', active: false },
    ]

    defaultTimeFrom = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 10, 0)
    defaultTimeTo = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 19, 0)

    week = [
        {
            index: 1,
            name: 'Понедельник',
            open: false,
            twentyFour: false,
            time: { from: this.defaultTimeFrom, to: this.defaultTimeTo },
            recess: null,
            code: '',
            title: '',
        },
        {
            index: 2,
            name: 'Вторник',
            open: false,
            twentyFour: false,
            time: { from: this.defaultTimeFrom, to: this.defaultTimeTo },
            recess: null,
            code: '',
            title: '',
        },
        {
            index: 3,
            name: 'Среда',
            open: false,
            twentyFour: false,
            time: { from: this.defaultTimeFrom, to: this.defaultTimeTo },
            recess: null,
            code: '',
            title: '',
        },
        {
            index: 4,
            name: 'Четверг',
            open: false,
            twentyFour: false,
            time: { from: this.defaultTimeFrom, to: this.defaultTimeTo },
            recess: null,
            code: '',
            title: '',
        },
        {
            index: 5,
            name: 'Пятница',
            open: false,
            twentyFour: false,
            time: { from: this.defaultTimeFrom, to: this.defaultTimeTo },
            recess: null,
            code: '',
            title: '',
        },
        {
            index: 6,
            name: 'Суббота',
            open: false,
            twentyFour: false,
            time: { from: this.defaultTimeFrom, to: this.defaultTimeTo },
            recess: null,
            code: '',
            title: '',
        },
        {
            index: 7,
            name: 'Воскресенье',
            open: false,
            twentyFour: false,
            time: { from: this.defaultTimeFrom, to: this.defaultTimeTo },
            recess: null,
            code: '',
            title: '',
        },
    ];

    constructor() {
    }

    getCompanyInfo = (id) => {
        return geoDataService.getCompanyInfo(id).then(result => {
            return result
        })
    }

    getAddressSuggestion = (query) => {
        return geoDataService.getAddressSuggestion(query)
            .then(result => {
                return result
            })
            .catch(e => e)
    }

    getAddress = (coords = {}) => {
        return geoDataService.getAddress(coords).then(result => {
            const correctData = JSON.parse(result);

            if (correctData.suggestions.length === 0) {
                return Promise.reject('error')
            } else {
                const address = {
                    //unrestricted_value: correctData.suggestions[0].unrestricted_value,
                    country: correctData.suggestions[0].data.country ?? '',
                    region: correctData.suggestions[0].data.region ?? '',
                    city: correctData.suggestions[0].data.city_with_type ?? '',
                    postal_code: correctData.suggestions[0].data.postal_code ?? '',
                    street: correctData.suggestions[0].data.street ?? '',
                    house: correctData.suggestions[0].data.house ?? ''
                }

                this.setAddressField(address);

                return Promise.resolve('OK')
            }

        })
            .catch(e => {
                return e
            })
    }

    setAddressField = (addressObject = {}) => {
        this.setFormFieldValue('country', addressObject.country)
        this.setFormFieldValue('region', addressObject.region)
        this.setFormFieldValue('city', addressObject.city)
        this.setFormFieldValue('postal_code', addressObject.postal_code)
        this.setFormFieldValue('street', addressObject.street)
        this.setFormFieldValue('house', addressObject.house)

        const addressFiled = {
                "country": addressObject.country,
                "region": addressObject.region,
                "city": addressObject.city,
                "postcode": addressObject.postal_code,
                "street": addressObject.street,
                "building": addressObject.house,
        }

        this.setFormFieldValue('address', addressFiled)
    }

    getFieldValue = (code = '', src = []) => {
        return src.find(item => {
            return item.code === code
        })
    }

//TODO разбить на отдельные функции и переместить в сервис
    saveCompanyData = (id) => {
        let data = {};

        try {
            ['title', 'short_title', 'ok_link', 'tg_link', 'twitter_link', 'viber_phone', 'vk_url', 'whatsapp_phone', 'youtube_link']
                .map(k => {
                    data = {
                        ...data,
                        [this.form[k].code]: this.form[k].value.length !== 0 ? this.form[k].value : null,
                    }
                })

            const sp = this.form.setupPlace.find(sp => {
                return sp.code === 'firstSetup'
            })

            sp.blocks.map(bl => {
                bl.fields.map(f => {
                    data = {
                        ...data,
                        [f.code]: f.fieldValue,
                    }
                })
            })

            const phonesArr = this.form['phones'].value.map(phone => {

                return phone;

            })

            data = {
                ...data,
                ...{ [this.form['phones'].code]: phonesArr.filter(val => val.number !== '') }
            }

            const sp2 = this.form.setupPlace.find(sp => {
                return sp.code === 'phoneInfoSetup'
            })

            sp2.blocks.map(bl => {
                bl.fields.map(f => {
                    if (f.fieldName === 'phone') {
                        const phonesArr = f.fieldValue.map(phone => {
                            return phone
                        })
                        data = {
                            ...data,
                            [f.code]: phonesArr.filter(val => val.number !== ''),
                        }

                    }
                })
            })


            const emailsArr = this.form['emails'].value.map(email => {
                return email;
            })

            data = {
                ...data,
                ...{ [this.form['emails'].code]: emailsArr.filter(val => val !== '') }
            }

            const websiteArr = this.form['websites'].value.map(website => {
                return website;
            })

            data = {
                ...data,
                ...{ [this.form['websites'].code]: websiteArr.filter(val => val !== '') }
            }

            const sp3 = this.form.setupPlace.find(sp => {
                return sp.code === 'socialMediaSetup'
            })

            sp3.blocks.map(bl => {
                bl.fields.map(f => {
                    if (f.fieldName === 'websites') {
                        data = {
                            ...data,
                            [f.code]: f.fieldValue.filter(val => val !== ''),
                        }
                    }
                })
            })

            let workTime = [];

            const parseTime = (time = null) => {
                if(!time) {
                    return null
                }

                return new Intl.DateTimeFormat("ru-RU", {
                    hour: "numeric",
                    minute: "numeric",
                }).format(time)
            }


            this.form.workTime.value.map(val => {
                if (val.open) {
                    let work = {};
                    let brea;

                    if (val.time.from && val.time.to) {
                        work = {
                            "start": parseTime(val.time.from),//moment(val.time.from).utcOffset(0).format("HH:mm"),
                            "finish": parseTime(val.time.to)//moment(val.time.to).utcOffset(0).format("HH:mm")
                        }
                    }

                    if (val.recess) {
                        brea = {
                            "start": parseTime(val.recess.from),//moment(val.recess.from).utcOffset(0).format("HH:mm"),
                            "finish": parseTime(val.recess.to), //moment(val.recess.to).utcOffset(0).format("HH:mm")
                        }
                    }

                    workTime.push({
                        "day": val.index,
                        "work": work,
                        "break": brea
                    })
                }
            })

            data = {
                ...data,
                [this.form.workTime.code]: workTime,
            }

            let coordinates = {
                comment: this.form.addressComment.value,
                latitude: +this.form.latitude.value,
                longitude: +this.form.longitude.value,
            };

            data = {
                ...data,
                ...{ coordinates },
            };

            let address = {...this.form.address.value};

            data = {
                ...data,
                ...{ address },
            };

            const spCategory = this.form.setupPlace.find(sp => {
                return sp.code === 'legalInfoSetup'
            })

            spCategory.blocks.map(bl => {
                let deletedField = []

                bl.fields.map(f => {
                    let fieldValue = [];
                    if(f.fieldValue?.length > 0) {
                        fieldValue = f.fieldValue.map((value,index) => {
                            return {
                                id: value.id,
                                title: value.title,
                                //TODO придумать как работать с основными категориями (они у нас не предусмотрены)
                                //TODO сейчас основной категорией является первый элемент массива
                                primary: index === 0,
                            }
                        })
                    }

// тут массив с удаленными категориями
                    this.deletedFields.map((df) => {
                        if(df.code === f.code) {
                            deletedField.push(df)
                        }
                    })

                    if(deletedField) {
                        deletedField.map(df => {
                            fieldValue.push({
                                id: df.id,
                                _del: true
                            })
                        })
                    }

                    data = {
                        ...data,
                        [f.code]: fieldValue,
                    }
                })
            })

            const categoryArr = this.form['category'].value.map((category, index )=> {
                return {
                    id: category.id,
                    title: category.title,
                    primary: index === 0,
                };
            })

            let deletedField = []
            this.deletedFields.map((df) => {
                if(df.code === null) {
                    deletedField.push(df)
                }
            })

            if(deletedField) {
                deletedField.map(df => {
                    categoryArr.push({
                        id: df.id+'',
                        _del: true
                    })
                })
            }

            data = {
                ...data,
                ...{ [this.form['category'].code]: categoryArr }
            }

            if (this.deletedBlocks.length > 0) {
                this.deletedBlocks.map(db => {
                    data = {
                        ...data,
                        [db.value]: db.del,
                    }
                })
            }

            //console.log(data)
            //return Promise.resolve('OK')

            return geoDataService.saveCompanyData(id, data).then(result => {
                return result
            })
                .catch(e => e)

        }
        catch (e) {
            return Promise.reject('Ошибка сохранения данных')
        }

    }

    getChildrenRubrics = (id) => {
        return geoDataService.getChildrenRubrics(id).then(result => {
            return result
        })
            .catch(e => e)
    }


    @action getCompanyData = (id) => {
        return geoDataService.getCompanyData(id).then(result => {
            if (!!result.items.length) {
                this.dataModel = result;

//TODO разбить на отдельные функции и переместить в сервис
                const coordinates = this.getFieldValue('coordinates', result.items)
                if (coordinates !== undefined) {
                    this.form.latitude = {
                        ...this.form.latitude,
                        ...{
                            value: coordinates.value.latitude,
                            code: 'latitude',
                            title: coordinates.title
                        }
                    }
                    this.form.longitude = {
                        ...this.form.longitude,
                        ...{
                            value: coordinates.value.longitude,
                            code: 'longitude',
                            title: coordinates.title
                        }
                    }

                    this.form.addressComment = {
                        ...this.form.addressComment,
                        ...{
                            value: coordinates.value.comment,
                            code: 'comment',
                            title: coordinates.title
                        }
                    }
                }

                const title = this.getFieldValue('title', result.items)
                const short_title = this.getFieldValue('short_title', result.items)

                if(title !== undefined) {
                    this.form.title = {
                        ...this.form.title,
                        ...{
                            value: title.value,
                            code: title.code,
                            title: title.title
                        }
                    }
                }

                if(short_title !== undefined) {
                    this.form.short_title = {
                        ...this.form.short_title,
                        ...{
                            value: short_title?.value,
                            code: short_title?.code,
                            title: short_title?.title
                        }
                    }
                }
                ['ok_link', 'tg_link', 'twitter_link', 'viber_phone', 'vk_url', 'whatsapp_phone', 'youtube_link']
                    .map((field)=>{
                        const filedValue = this.getFieldValue(field, result.items)
                        if(filedValue !== undefined) {
                            this.form[filedValue.code] = {
                                ...this.form[filedValue.code],
                                ...{
                                    value: filedValue.value,
                                    code: filedValue.code,
                                    title: filedValue.title
                                }
                            }
                        }

                    })

                let pl = this.form.setupPlace.find(pl => {
                    return pl.code === 'firstSetup';
                })

                if (pl) {
                    if(short_title !== undefined) {
                        short_title.override?.map(item => {
                            if (item.code) {
                                pl.blocks.push(
                                    {
                                        source: item.source,
                                        name: getDetailDataByPlatform(item.source).name,
                                        active: false,
                                        fields: [{
                                            "fieldName": item.code,
                                            "code": item.code,
                                            "fieldLabel": item.title,
                                            "fieldValue": item.value,
                                            "fieldType": "text",
                                            "required": false
                                        }]
                                    }
                                )
                                pl.blocks[0].active = true;
                            }
                        })
                    }
                    if(title !== undefined) {
                        title.override?.map(item => {
                            if (item.code) {
                                let bl = pl.blocks.find(bl => {
                                    return bl.source === item.source
                                })

                                bl.fields.push(
                                    {
                                        "fieldName": item.code,
                                        "code": item.code,
                                        "fieldLabel": item.title,
                                        "fieldValue": item.value,
                                        "fieldType": "text",
                                        "required": false
                                    }
                                )

                            }
                        })
                    }
                }

                const category = this.getFieldValue('category', result.items)
                if(category !== undefined) {
                    this.form.category = {
                        ...this.form.category,
                        ...{
                            code: category.code,
                            title: category.title,
                            primary: category.primary,
                        }
                    }
                    category.value?.map((c, idx) => {
                        this.form.category.value[idx] = {
                            id: c.id,
                            title: c.title,
                            primary: c.primary,
                            children: [],
                        }
                    })

                    let lis = this.form.setupPlace.find(pl => {
                        return pl.code === 'legalInfoSetup';
                    })

                    category.override?.map(item => {
                        if(item.value.length > 0) {

                            const index = lis.blocks.findIndex((bl) => {
                                return bl.source === item.source
                            })

                            lis.blocks.splice(index, 1)

                            const values = item.value.map((category) => {
                                return {
                                    id: category.id,
                                    title: category.title,
                                    primary: category.primary,
                                    children: [] //??
                                }
                            })

                            lis.blocks.push(
                                {
                                    source: item.source,
                                    name: getDetailDataByPlatform(item.source).name,
                                    active: false,
                                    fields: [{
                                        "fieldName": "categoryList",
                                        "code": item.code,
                                        "fieldLabel": category.title,
                                        "fieldValue": values,
                                        "fieldType": "dropdown",
                                        "required": true
                                    }]
                                }
                            )

                        }

                    })

                    lis.blocks[0].active = true;
                }

                const phones = this.getFieldValue('phones', result.items)
                if (phones !== undefined) {
                    this.form.phones = {
                        ...this.form.phones,
                        ...{
                            code: phones.code,
                            title: phones.title,
                        }
                    }

                    phones.value.map((phone, idx) => {
                        this.form.phones.value[idx] = phone
                    })
                }
                let pis = this.form.setupPlace.find(pl => {
                    return pl.code === 'phoneInfoSetup';
                })
                if (pis) {
                    if(phones !== undefined) {
                        phones.override?.map(item => {
                            const values = item.value.map((phone) => {
                                return phone
                            })

                            pis.blocks.push(
                                {
                                    source: item.source,
                                    name: getDetailDataByPlatform(item.source).name,
                                    active: false,
                                    fields: [{
                                        "fieldName": "phone",
                                        "code": item.code,
                                        "fieldLabel": phones.title,
                                        "fieldValue": values,
                                        "fieldType": "dynamic-text",
                                        "required": false
                                    }]
                                }
                            )
                        })
                    }

                    if(pis.blocks.length > 0) {
                        pis.blocks[0].active = true;
                    }
                }


                const emails = this.getFieldValue('emails', result.items)

                if (emails !== undefined) {

                    this.form.emails = {
                        ...this.form.emails,
                        ...{
                            code: emails.code,
                            title: emails.title,
                        }
                    }

                    emails.value.map((email, idx) => {
                        this.form.emails.value[idx] = email
                    })
                }
                else {

                }

                let cis = this.form.setupPlace.find(pl => {
                    return pl.code === 'contactInfoSetup';
                })

                if (cis) {
                    if (emails!== undefined) {
                        emails.override?.map(item => {
                            let bl = cis.blocks.find(bl => {
                                return bl.source === item.source
                            })

                            bl.fields.push(
                                {
                                    "fieldName": "email",
                                    "code": item.code,
                                    "fieldLabel": emails.title,
                                    "fieldValue": item.value,
                                    "fieldType": "dynamic-text",
                                    "required": false
                                }
                            )
                        })
                    }

                    if(cis.blocks.length > 0) {
                        cis.blocks[0].active = true;
                    }

                }

                const websites = this.getFieldValue('websites', result.items)
                if(websites !== undefined) {
                    this.form.websites = {
                        ...this.form.websites,
                        ...{
                            code: websites.code,
                            title: websites.title,
                        }
                    }
                    websites.value.map((email, idx) => {
                        this.form.websites.value[idx] = email
                    })

                    let sms = this.form.setupPlace.find(pl => {
                        return pl.code === 'socialMediaSetup';
                    })

                    if (sms) {
                        websites.override?.map(item => {

                            sms.blocks.push(
                                {
                                    source: item.source,
                                    name: getDetailDataByPlatform(item.source).name,
                                    active: false,
                                    fields: [{
                                        "fieldName": "websites",
                                        "code": item.code,
                                        "fieldLabel": websites.title,
                                        "fieldValue": item.value,
                                        "fieldType": "dynamic-text",
                                        "required": false
                                    }]
                                }
                            )

                        })
                        if(sms.blocks.length > 0) {
                            sms.blocks[0].active = true;
                        }
                    }
                }

                const workTime = this.getFieldValue('work-time', result.items)
                if (workTime !== undefined) {

                    workTime.value.map(day => {
                        const dayIndex = this.week.findIndex(w => {
                            return w.index === day.day
                        })

                        const [dws_h, dws_m] = day.work.start.split(':');
                        const [dwe_h, dwe_m] = day.work.finish.split(':');

                        let recess = null;
                        if (day['break']) {
                            const [dbs_h, dbs_m] = day['break'].start.split(':');
                            const [dbe_h, dbe_m] = day['break'].finish.split(':');
                            recess = {
                                from: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), dbs_h, dbs_m),
                                to: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), dbe_h, dbe_m),
                            }

                        }

                        this.week[dayIndex] = {
                            ...this.week[dayIndex],
                            ...{
                                open: true,
                                time: {
                                    from: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), dws_h, dws_m),
                                    to: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), dwe_h, dwe_m),
                                },
                                recess
                            },
                            ...{
                                code: workTime.code,
                                title: workTime.title
                            }
                        }

                    })
                }

                const address = this.getFieldValue('address', result.items)
                if(address !== undefined) {
                    this.form.address = {
                        ...this.form.address,
                        ...{
                            value: address.value,
                        }
                    }
                }
            }
            return Promise.resolve(result)
        })
    }

    @action saveInMainCategoryList = (data) => {
        this.mainCategoryList = data;
    }

    checkArrayField = () => {
        let fieldsError = [];
        Object.keys(this.form).map(key => {
            if (typeof this.form[key].value === 'object' && this.form[key].required === true && !this.form[key].value[0]) {
                fieldsError.push(`${key}_0`)
            }
        })
        return fieldsError
    }

    checkSetupFields = () => {
        let fieldsError = [];
        this.form.setupPlace.map(place => {
            place.blocks.map(block => {
                block.fields.map(field => {
                    if (field.fieldValue === '' && field.required === true) {
                        fieldsError.push(place.code + '_' + block.source + '_' + field.fieldName)
                    }
                    if (field.fieldValue?.length <= 0 && field.required === true) {
                        fieldsError.push(place.code + '_' + block.source + '_' + field.fieldName)
                    }
                })
            })
        })
        return fieldsError;
    }

    checkPrimaryFields = () => {
        let fieldsError = [];
        Object.keys(this.form).map(key => {
            if (typeof this.form[key].value === 'string' && this.form[key].value === "" && this.form[key].required === true) {
                this.form[key].isValid = false;
                fieldsError.push(key)
            }
        })
        return fieldsError;
    }

    getCurrentSetup = (code) => {
        return this.form.setupPlace.find(setup => {
            if (setup.code === code) return setup.blocks;
        });
    }

    @action setValidate = (fieldName, valid) => {
        if (this.form.hasOwnProperty(fieldName)) {
            this.form[fieldName].isValid = valid;
        }
    }

    //TODO переименовать в saveFieldValueToStore
    @action setFormFieldValue = (fieldName, fieldValue) => {
        if (this.form.hasOwnProperty(fieldName)) {
            this.form[fieldName].value = fieldValue;
        }
    }

    @action removeDataFromField = (data, source = null) => {

        if(source) {
            const code = 'legalInfoSetup'
            const setup = this.form.setupPlace.find(setup => setup.code === code);

            if (setup) {
                const block = setup.blocks.find(block => block.source === source);

                block.fields.map((field) => {
                    const index = field.fieldValue.findIndex(item => item.id === data.id)
                    field.fieldValue.splice(index, 1);

                    this.deletedFields.push({
                        id: data.id,
                        code: field.code
                    })

                    if(field.fieldValue?.length > 0) {
                        field.fieldValue[0].primary = true;
                    }
                })

            }
        }
        else {
            if(data.id) {
                const index = this.form.category.value.findIndex(item => item.id === data.id)
                this.form.category.value.splice(index, 1);

                this.deletedFields.push({
                    id: data.id,
                    code: null,
                })
                if(this.form.category.value.length > 0) {
                    this.form.category.value[0].primary = true;
                }
            }

        }
    }

    @action moveDataInField = (source, code) => {

        if (this.mainCategoryList.length) {
            let outputArray = [];
            this.mainCategoryList.map((category) => {
                category.children.map(child => {
                    const tmp = { id: child.id, title: child.title, code: child.type.code }

                    let found = false;
                    outputArray.map(outItem => {
                        if (outItem.code === child.type.code) {
                            outItem.data.push(tmp)
                            found = true;
                            return 0;
                        }
                    })

                    if (!found) {
                        outputArray.push({ code: child.type.code, data: [tmp] });
                    }

                })
            })

            outputArray.map(item => {
                if (source === item.code) {
                    this.setSetupFieldValue(code, item.code, 'categoryList', item.data)
                }
            })
        }
    }

    getUniqCategory(dest, src) {
        if (!dest) return src;
        src.map(item => {
            if (!dest.find(element => element.id === item.id)) {
                dest.push(item);
            }
        })
        return dest;
    }

    limitsOnSource = {
        '2gis': {
            limits: 10000,
            text: ''
        },
        google: {
            limits: 10,
            text: 'Google Business Profile поддерживает до 10 категорий для карточки.'
        },
        yandex: {
            limits: 3,
            text: 'Yandex Карты поддерживает до 3 категорий для карточки.'
        }
    }

    @action saveValueInSetup(code, fieldSource, object) {

        const setup = this.form.setupPlace.find(setup => setup.code === code);
        if (setup) {
            const block = setup.blocks.find(block => block.source === fieldSource);

            if(block) {
                block.fields.map((field) => {
                    const present = field.fieldValue.find(fv => fv.id === object.id)
                    if (!present) {
                        if(field.fieldValue.length < this.limitsOnSource[fieldSource].limits) {
                            field.fieldValue.push({
                                id: object.id,
                                title: object.title,
                                primary: false,
                                children: []
                            })
                        }
                    }
                })
            }
            else {
                //FIXME после новых правок новый блок никогда не создается (они уже существуют)
                setup.blocks.push({
                    "source": fieldSource,
                    "name": getDetailDataByPlatform(fieldSource).name,
                    "active": false,
                    "fields": [
                        {
                            "fieldName": "category",
                            "code": `category@${fieldSource}`,
                            "fieldLabel": "Категория",
                            "fieldValue": [
                                {
                                    id: object.id,
                                    title: object.title,
                                    primary: true,
                                    children: []
                                }
                            ],
                            "fieldType": "dropdown",
                            "required": false
                        },
                    ],
                })
                setup.blocks[0].active = true;
            }
        }
    }

//TODO переименовать saveSetupFieldValueToSore
    /*
        @action setSetupFieldValue = (code, fieldSource, fieldName, fieldValue) => {
            const setup = this.form.setupPlace.find(setup => setup.code === code);

            if (setup) {
                const block = setup.blocks.find(block => block.source === fieldSource);
                block.fields.map((field, index) => {

                    if (field.fieldName === fieldName) {
                        const newField = this.getUniqCategory(block.fields[index].fieldValue, fieldValue)
                        block.fields[index] = {
                            ...field,
                            ...{ fieldValue: newField }
                        }
                    }
                })
            }
        }
    */

    @action setSetupFieldValue = (code, fieldSource, fieldName, fieldValue) => {

        const setup = this.form.setupPlace.find(setup => setup.code === code);
        if (setup) {
            const block = setup.blocks.find(block => block.source === fieldSource);
            block.fields.map((field, index) => {
                if (field.fieldName === fieldName) {
                    block.fields[index] = {
                        ...field,
                        ...{ fieldValue: fieldValue }
                    }
                }
            })
        }
    }

    getSetupFieldValueFromStore = (code, fieldSource, fieldName) => {
        const setup = this.form.setupPlace.find(setup => setup.code === code)
        if (setup) {
            const block = setup.blocks.find(block => block.source === fieldSource);
            const field = block.fields.find(field => field.fieldName === fieldName)
            if (field) {
                return field.fieldValue
            }
        }
        return ['']
    }

    @action setFromParentSetupFieldValue = (code, source, parentFieldName) => {
        if (this.form.hasOwnProperty(parentFieldName)) {
            let outputArray = [];

            this.form[parentFieldName].value.map(item => {
                item.children.map(child => {
                    const tmp = { id: child.id, title: child.title, code: child.type.code }

                    let found = false;
                    outputArray.map(outItem => {
                        if (outItem.code === child.type.code) {
                            outItem.data.push(tmp)
                            found = true;
                            return 0;
                        }
                    })

                    if (!found) {
                        outputArray.push({ code: child.type.code, data: [tmp] });
                    }

                })
            })
            outputArray.map(item => {
                if (source === item.code) {
                    this.setSetupFieldValue(code, item.code, 'categoryList', item.data)
                }
            })
        }

    }

    @action getSetupFieldValue = (code, source) => {
        const place = this.form.setupPlace.find(item => item.code === code)
        let value = [];
        place.blocks.map(block => {
            if (block.fields[0].fieldValue !== '') {
                block.fields[0].fieldValue.map(field => {
                    if (field.code === source) {
                        value.push({ 'id': field.id, 'title': field.title, code: field.code });
                    }
                });
            }
        })
        return value
    }

    @action getFormFieldValue = (fieldName) => {

        if (this.form.hasOwnProperty(fieldName)) {
            this.form[fieldName].value.map(val => {
                if (val.hasOwnProperty('children')) {

                    val.children.map(child => {
                        this.parentCategoryList.push({ 'id': child.id, 'title': child.title, code: child.type.code })
                    })
                }
            })
            return this.form[fieldName].value;
        }
    }

    @action pushSetupPlaceValue = (code, fieldValue) => {
        const setup = this.form.setupPlace.find(setup => setup.code === code);

        if (setup) {
            setup.blocks.push(fieldValue)
        }
    }

    @action removeSetupPlace = (code = "") => {
        const setup = this.form.setupPlace.find(setup => setup.code === code);

        if (setup) {
            setup.blocks.map(s => {
                s.fields.map(f => {
                    geoStore.deletedBlocks.push(
                        {
                            value: f.code,
                            del: geoStore.blocks[setup.code].del
                        }
                    )
                })
            })

            setup.blocks = []
        }
    }

    @action removeSetupPlaceItem = (code = "", source = "") => {
        const setup = this.form.setupPlace.find(setup => setup.code === code);

        if (setup) {
            if (geoStore.blocks[code]) {
                geoStore.blocks[code].value.map(c => {
                    geoStore.deletedBlocks.push(
                        {
                            value: `${c}@${source}`,
                            del: geoStore.blocks[code].del
                        }
                    )
                })
            }

            const index = setup.blocks.findIndex(place => place.source === source)
            if (index >= 0) {
                setup.blocks.splice(index, 1);

                if (setup.blocks.length) {
                    setup.blocks[0].active = true;
                }
            }
        }
    }
}


class GeoDataStore {

    #geoDataService;

    @observable companyList = [];

    @observable filter = {
        title: '',
        page: {
            size: 10,
            number: 1,
        },
        sort: {
            column: "title",
            order: "asc",
        }
    }

    constructor(geoDataService) {
        this.#geoDataService = geoDataService;
    }


    getCompanyList() {
        return this.#geoDataService.getCompanyList()
            .then(result => {
                // тут результат привести к модели geoDataCompany (еще не готово)
                return result
            })
    }


    getPlatformData = (companyId) => {

        return this.#geoDataService.getPlatformData(companyId)
            .then(result => result)
            .catch(e => e)
    }
}

const geoDataStore = new GeoDataStore(geoDataService)
const geoStore = new GeoStore();

export { geoStore, geoDataStore }
